export const environment = {
  location: 'USA',
  code: 'en-US',
  production: true,
  features: {
    dashboard: { preload: true, canBeActivated: true, show: true },
    myAccounts: { preload: true, canBeActivated: true, show: true },
    aigTeams: { preload: true, canBeActivated: true, show: true },
    documents: { preload: true, canBeActivated: true, show: true },
    lossRuns: { preload: true, canBeActivated: true, show: true },
    policies: { preload: true, canBeActivated: true, show: true },
    renewals: { preload: true, canBeActivated: true, show: true },
    insights: { preload: true, canBeActivated: true, show: true },
    tools: { preload: true, canBeActivated: true, show: true },
    producerAppointments: { preload: true, canBeActivated: true, show: true },
    appetite: { preload: true, canBeActivated: true, show: true },
    billing: { preload: true, canBeActivated: true, show: true },
    analytics: { preload: true, canBeActivated: true, show: true },
    admin: { preload: true, canBeActivated: true, show: true },
  },
  prototype: false
};
