import { AppConfig } from '@config';
import { enableProdMode, Injector } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { Router } from '@angular/router';
import { OKTA_CONFIG } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';

import { environment } from './environments/environment';
const RSWPath = window.location.host + '/rsw';
const ESRIpath = window.location.host + '/esri';
if (environment.production) {
  enableProdMode();
  let version = window['version'] || {}
  Object.keys(version).forEach(key => AppConfig[key] = version[key]);
}
const mapConfigs = async (url) => {
  const configUrl = url;
  let res = await fetch(configUrl);
  let config = await res.json();
  Object.keys(config).forEach(key => AppConfig[key] = config[key]);
};
//Self calling async function to get configuration for app & okta before application loads
(async () => {
  await mapConfigs(`assets/config/${AppConfig['config.json']}`);
  if (window.location.href.includes(RSWPath)) {
    await mapConfigs(`assets/config/${AppConfig['config-rsw.json']}`);
  } else if (window.location.href.includes(ESRIpath)) {
    await mapConfigs(`assets/config/${AppConfig['config-esri.json']}`);
  }
  const AppModule = await (await import('./app/app.module')).AppModule;
  platformBrowserDynamic(
    [{
      provide: OKTA_CONFIG,
      useFactory: () => {
        const oktaAuth = new OktaAuth(AppConfig.okta);
        return {
          oktaAuth,
          onAuthRequired: (oktaAuth: OktaAuth, injector: Injector) => {
            const router = injector.get(Router);
            router?.navigate([AppConfig.okta.appLoginLink]);
          },
        };
      },
    }]
  )
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
})();


