

export const AppConfig: any = {
  appTitle: 'myAIG Broker Portal',
  apiBaseUrl: '',
  appUserBaseUrl: '',
  whyAigLinks: '',
  aigAppetiteGuideLink: '',
  aigTermsOfUseLink: '',
  aigPrivacyPolicyLink: '',
  aigLegalNoticeLink: '',
  aigWebsiteLink: '',
  aigeStartLink: '',
  analyticsUrl: '',
  okta: {
    clientId: '',
    issuer: '',
    redirectUri: '',
    postLogoutRedirectUri: '',
    scopes: [],
  },
  enableAuthGuard: true,
  appId: '',
  docId: '',
  analytics: {
    nonce: '',
    site: {
      name: '',
      country: '',
      language: '',
    },
    url: ''
  },
  basicAuthEmailNotif: '',
  termAndCondEnabled: '',
  dynaTrace: {
    tenantId: '',
    webAppId: '',
    baseURL: '',
    enableDynaTrace: false,
  },
  defaultTnCVersion: '',
  publishedDate: '',
  lastUpdatedDate: '',
  sessionTimeoutWarning: '',
  sessionTimeLeft: '',
  enableAppTourOnLoad: '',
  excess: {
    eExcessActionNewBusinessSub: '',
    eExcessActionExistingSub: '',
  },
  dmv: {
    dmvAutoLiabilityInsurance: '',
    californiaCommericalInsurance: '',
  },
  mapConfig: '',
  restrictedCountries: '',
  maintenanceMode: false,
  messages: {
    lossRun: {}
  },
  inputLength: {
    maxLength40: '',
    maxLength30: '',
    maxLength250: '',
  },
  adobeAnalytics: {},
  enableMessenger: '',
  messengerFileLink: '',
  messengerDetails: '',
  authScopeName: undefined,
  defaultLoginUrl: '',
  cybermaticsUrl: '',
  eBacBillingWithOutSubTotal: '',
  eBacBillingWithSubTotal: '',
  rswApp: '',
  restrictProducerResultCount: undefined,
  config: '',
  'config-help.json': 'config-help.json',
  'config-rsw.json': 'config-rsw.json',
  'config-analytics.json': 'config-analytics.json',
  'config-broker-okta-status.json': 'config-broker-okta-status.json',
  'config-dmv.json': 'config-dmv.json',
  'config-tools.json': 'config-tools.json',
  'config.json': 'config.json',
  'config-esri.json': 'config-esri.json',
  'en.json': 'en.json'
};
